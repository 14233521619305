<template>
  <Teleport to="body">
    <sweet-modal
      ref="modal"
      title="Select an article to include"
      @close="$emit('close')"
    >
      <div>
        <div class="search-wrap">
          <input
            ref="searchInput"
            v-model="searchText"
            type="text"
            class="form-control search-input"
            placeholder="Search your articles..."
            name="search"
            @keyup="onArticleSearch"
          />
          <i
            class="nulodgicon-ios-search-strong search-input-icon"
            @click.prevent="$refs.searchInput.focus"
          />
        </div>
        <div v-if="articles">
          <div class="h6 mt-5">
            Results
            <span
              v-if="articles.length > 0"
              class="ml-2 text-muted small"
            >
              Click to link the Article.
            </span>
            <span
              v-if="loadingStatus"
              class="ml-2 d-inline-flex"
            >
              <pulse-loader
                color="#0d6efd"
                size="0.5rem"
                :loading="true"
              />
            </span>
          </div>
          <div v-if="articles.length > 0">
            <div
              v-for="article in articles"
              :key="article.id"
              class="row justify-content-between clickable selectable-option text-themed-dark bg-themed-lighter rounded m-2 p-2"
              @click="$emit('input', article)"
            >
              <div class="col-9 pl-1 task-group-info align-content-center">
                <span class="article-title">{{ truncate(article.title, 60) }}</span>
              </div>
              <div class="col d-inline-flex p-0 justify-content-end align-items-center">
                <div
                  v-tooltip="'Insert Article'"
                  class="mx-1 basic-transition option__link-wrap btn btn-link btn-flat btn-icon-circle btn-icon-circle-sm text-secondary"
                >
                  <i class="mb-0 nulodgicon-link" />
                </div>
              </div>
            </div>
            <nav v-if="articlesPageCount > 1">
              <paginate
                ref="paginate"
                class="my-3 px-2 justify-content-center"
                :click-handler="pageSelected"
                :container-class="'pagination pagination-sm'"
                :next-class="'next-item'"
                :next-link-class="'page-link'"
                :next-text="'Next'"
                :page-class="'page-item'"
                :page-count="articlesPageCount"
                :page-link-class="'page-link'"
                :prev-class="'prev-item'"
                :prev-link-class="'page-link'"
                :prev-text="'Prev'"
                :selected="articlesPage"
              />
            </nav>
          </div>
          <div
            v-else
            class="h6 text-muted"
          >
            No results found. Please modify your search.
          </div>
        </div>
      </div>
    </sweet-modal>
  </Teleport>
</template>

<script>
import search from 'mixins/search';
import strings from 'mixins/string';
import { SweetModal } from 'sweet-modal-vue';
import Paginate from 'vuejs-paginate';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
  components: {
    SweetModal,
    Paginate,
    PulseLoader,
  },
  mixins: [ search, strings ],
  data() {
    return {
      searchText: null,
    };
  },
  computed: {
    ...mapGetters([
      'articles',
      'articlesPageCount',
      'articlesPage',
      'loadingStatus',
    ]),
  },
  methods: {
    ...mapMutations(['setArticlesPage']),
    ...mapActions(['fetchArticles']),

    open() {
      this.fetchArticles({ search: this.searchText });
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onArticleSearch() {
      this.fetchArticles({ search: this.searchText });
    },
    pageSelected(p) {
      this.setArticlesPage(p - 1)
      this.fetchArticles({ search: this.searchText });
    },
  },
};
</script>

<style lang="scss" scoped>
  .selectable-option {
    &:hover {
      .option__link-wrap {
        background-color: var(--themed-light-hover-bg) !important;
        color: $themed-secondary !important;
      }
    }
  }
</style>
