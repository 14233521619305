<template>
  <div :class="isHelpCenter ? 'm-6' : 'mb-5'">
    <div
      v-if="articleLoadingStatus"
      class="my-5 clearfix"
    >
      <pulse-loader
        :loading="true"
        class="ml-3 mt-1"
        color="#0d6efd"
        size="0.8rem"
      />
    </div>
    <div
      v-else-if="article && article.slug"
      class="mt-5"
    >
      <onboarding-title
        v-if="isHelpCenter"
        :image-src="onboardingTitle.imageSrc"
        :header="onboardingTitle.header"
        :sub-header="onboardingTitle.subHeader"
      />
      <div class="d-flex align-items-start mt-5">
        <div class="col-9 px-0">
          <div>
            <h3 class="mb-0 mr-3 title-size">
              {{ article.title }}
            </h3>
            <span
              v-for="tag in article.tags"
              :key="tag"
              class="badge badge-bg text-white mr-2"
              style="border-radius: 4px;"
            >
              {{ tag }}
            </span>
            <br>
            <div class="d-flex align-items-end mt-3">
              <div class="avatar-margin">
                <avatar
                  v-tooltip="article.authorFullName"
                  class="d-inline-block align-middle mr-1 logo-outline"
                  :size="30"
                  :src="article.avatarThumbUrl"
                  :username="article.authorFullName"
                />
              </div>
              <div class="not-as-small d-flex align-items-center">      
                <div>
                  <span class="text-muted">
                    {{ article.category }}
                  </span>
                  <span
                    v-if="locationName"
                    class="text-muted"
                  >
                    <span class="px-2 text-muted">&bull;</span>
                    <i class="genuicon-company mr-0 align-middle"/>
                    {{ locationName }}
                  </span>
                  <span
                    v-if="updatedAt(article)"
                    class="text-muted"
                  >
                    <span class="px-2 text-muted">&bull;</span>
                    <i class="genuicon-calendar-o align-middle" />
                    Updated on {{ updatedAt(article) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 d-flex align-items-center justify-content-end">
          <div
            class="text-secondary mr-4 p-2 not-as-small clickable"
            @click="goToIndex"
          >
            <i class="nulodgicon-arrow-left-c white mr-2" />
            <span class="p--responsive">Back to <strong>all articles</strong></span>
          </div>
          <a
            v-if="isWriteAnyArticle(article) && !isHelpCenter"
            v-tooltip="'Edit Article'"
            class="ml-1 btn btn-light btn-flat btn-icon-circle"
            data-tc-article-edit
            @click.stop.prevent="goToEdit"
          >
            <i class="nulodgicon-edit" />
          </a>
          <a
            v-if="isWriteAnyArticle(article) && !isHelpCenter"
            v-tooltip="'Delete Article'"
            class="ml-1 btn btn-light btn-flat btn-icon-circle"
            @click.stop.prevent="$refs.deleteArticleModal.open()"
          >
            <i class="nulodgicon-trash-b" />
          </a>
        </div>
      </div>
      <hr class="mt-2">
      <div class="d-flex justify-content-between">
        <div
          class="mt-2"
          :class="article.document.length ? 'col-10' : 'col-12'"
        >
          <p v-html="article.body"/>
        </div>
        <div
          v-if="article.document.length"
          class="col-2 p-3"
        >
          <h6 class="mb-3">
            Attached Documents:
          </h6>
          <div
            v-for="singleDoc in article.document"
            :key="singleDoc.id"
            class="d-flex align-items-center mb-1"
          >
            <a
              target="_blank"
              class="not-as-small"
              :href="`${singleDoc.url}`"
            >
              <img
                class="document-image"
                :src="`${singleDoc.iconUrl}`"
              >
              <span class="text-capitalize">
                {{ singleDoc.name ? singleDoc.name : singleDoc.attachedFileFileName }}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if ="isArticleNotFound"
      class="pt-5 mt-5 text-center"
    >
      <h4>Sorry, we could not find that article.</h4>
      <p>The link you followed may be broken, or the article may have been removed.</p>
    </div>

    <delete-article-modal
      ref="deleteArticleModal"
      :value="article"
      :show-page="isShowPage"
    />
  </div>
</template>

<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import permissionsHelper from 'mixins/permissions_helper';
  import Articles from "mixins/articles";
  import { mapGetters, mapMutations, mapActions } from 'vuex';
  import { Avatar } from 'vue-avatar';
  import OnboardingTitle from  'components/shared/module_onboarding/onboarding_title.vue';
  import DeleteArticleModal from "./delete_article_modal.vue";

  export default {
    components: {
      PulseLoader,
      DeleteArticleModal,
      Avatar,
      OnboardingTitle,
    },
    mixins: [permissionsHelper, Articles],
    data() {
      return {
        isShowPage: true,
        articleNotFound: false,
        onboardingTitle: {
          header: "Knowledge Base Article",
          subHeader: "Knowledge Base articles are guides with detailed help and info.",
          imageSrc: "https://nulodgic-static-assets.s3.amazonaws.com/images/article.png",
        },
      };
    },
    computed: {
      ...mapGetters([
        'article',
        'loadingStatus',
        'articleLoadingStatus',
      ]),
      locationName() {
        return this.article?.location?.name;
      },
      isArticleNotFound() {
        return this.articleNotFound;
      },
      isHelpCenter() {
        return this.$route.name === 'help_center_knowledge_base_show' || this.isBasicAccess;
      },
    },
    methods: {
      ...mapMutations([
        'setLoading',
        'setArticleLoadingStatus',
        'setArticle',
      ]),
      ...mapActions(['fetchArticle']),

      goToIndex() {
        if (this.isBasicAccess) {
          this.$router.push(`/knowledge/workspaces/${this.$route.params.workspace_id}`);
        } else if (this.isHelpCenter) {
          this.$router.push(`/knowledge_base/workspaces/${this.$route.params.workspace_id}`);
        } else {
          this.$router.push('/articles');
        }
      },
      goToEdit() {
        this.$router.push({ path: `/articles/${this.article.slug}/edit` });
      },
      onWorkspaceChange() {
        this.setArticleLoadingStatus(true);
        if (this.isHelpCenter) {
          const { params } = this.$route;
          this.fetchPublicArticle({ slug: params.slug, workspaceId: params.workspace_id }, 'helpCenterShow');
        } else {
          this.fetchArticle(this.$route.params.slug)
            .catch(error => {
              if (error?.response?.status === 404) {
                this.articleNotFound = true;
              } else {
                this.emitError(`Sorry, there was an issue loading the article. (${error.response.data.message})`);
              }
              this.setArticleLoadingStatus(false);
            });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .truncate-body {
    height: 3rem;
    max-height: 3rem;
    overflow: hidden;
  }
  .article-response:hover {
    background: $themed-lighter;
  }
  .edit-delete-btn:hover {
    text-indent: unset;
    background-color: $themed-very-fair;
  }
  .category-text {
    line-height: normal;
  }
  .badge-bg {
    background-color: #41b883;
  }
  .article-description {
    :deep(ul) {
      list-style-type: disc;
      padding-left: 15px;
    }
  }
  .title-size {
    font-size: 2.5rem
  }
  .avatar-margin {
    margin-top: 0.6rem
  }
  .document-image {
    width: 1.3rem
  }
  ::v-deep(.col-12 img),
  ::v-deep(.col-10 img) {
    max-width: 100%;
    height: auto;
  }
</style>
