<template>
  <sweet-modal
    ref="modal"
    v-sweet-esc
    title="Select a Response to include"
    @close="$emit('close')"
  >
    <div>
      <div class="search-wrap">
        <input
          ref="searchInput"
          v-model="search"
          type="text"
          class="form-control search-input"
          placeholder="Search your Responses..."
          name="search"
          @keyup.enter="fetchResults"
        >
        <i
          class="nulodgicon-ios-search-strong search-input-icon"
          @click.prevent="$refs.searchInput.focus"
        />
      </div>
      <div v-if="results">
        <div class="h6 mt-5">
          Results
          <span
            v-if="results.length > 0"
            class="ml-2 text-muted small"
          >
            Click on Response to add
          </span>
        </div>
        <div v-if="results.length > 0">
          <div
            v-for="result in results"
            :key="result.id"
            class="row justify-content-between clickable selectable-option text-themed-dark bg-themed-lighter rounded m-2 p-2"
            @click="$emit('input', result)"
          >
            <div class="col-9 pl-1 task-group-info align-content-center">
              <span class="article-title">{{ result.title }}</span>
            </div>
            <div class="col d-inline-flex p-0 justify-content-end align-items-center">
              <i
                v-if="result.companyId.toString() !== $currentCompanyId" 
                v-tooltip="'Response from parent company'"
                class="icon genuicon-parent-company mx-2" 
              />
              <div
                v-tooltip="'Insert Response'"
                class="mx-1 basic-transition option__link-wrap btn btn-link btn-flat btn-icon-circle btn-icon-circle-sm text-secondary"
              >
                <i class="mb-0 nulodgicon-link" />
              </div>
            </div>
          </div>
          <nav v-if="pageCount > 1">
            <paginate
              ref="paginate"
              class="my-3 px-2 justify-content-center"
              :click-handler="pageSelected"
              :container-class="'pagination pagination-sm'"
              :next-class="'next-item'"
              :next-link-class="'page-link'"
              :next-text="'Next'"
              :page-class="'page-item'"
              :page-count="pageCount"
              :page-link-class="'page-link'"
              :prev-class="'prev-item'"
              :prev-link-class="'page-link'"
              :prev-text="'Prev'"
              :selected="indexPage"
            />
          </nav>
        </div>
        <div class="text-center">
          <h4 v-if="results.length == 0">No responses found</h4>
          <router-link
            class="not-as-small"
            to="/responses/new"
            target="_blank"
          >
            + Add New
          </router-link>
        </div>
      </div>
    </div>
  </sweet-modal>
</template>

<script>
import http from 'common/http';
import { SweetModal } from 'sweet-modal-vue';
import Paginate from 'vuejs-paginate';

export default {
  components: {
    SweetModal,
    Paginate,
  },
  data() {
    return {
      perPage: 10,
      pageCount: 0,
      indexPage: 0,
      search: null,
      results: null,
    };
  },
  methods: {
    open() {
      this.search = null;
      this.results = null;
      this.fetchResults();
      this.$refs.modal.open();
    },

    close() {
      this.$refs.modal.close();
    },

    fetchResults() {
      if (this.search) {
        this.indexPage = 0;
      }
      http
        .get('/snippets.json', { params: {search: this.search, current_company: true, per_page: this.perPage, page: this.indexPage + 1 } })
        .then(({ data: { snippets, pageCount } }) => {
          this.results = snippets.sort((a, b) => a.title.localeCompare(b.title));
          this.pageCount = pageCount;
        })
        .catch(error => {
          this.emitError(`Sorry, there was an error fetching Responses. ${error.response.data.message}`);
        });
    },
    pageSelected(p) {
      this.indexPage = p - 1;
      this.fetchResults();
    },
  },
};
</script>

<style lang="scss" scoped>
.selectable-option {
  &:hover {
    .option__link-wrap {
      background-color: var(--themed-light-hover-bg) !important;
      color: $themed-secondary !important;
    }
  }
}
</style>